import * as yup from 'yup';
import { CalendarEventTypeEnum } from '../../../types';

export const AppointmentFiltersSchema = yup.object({
  focus: yup
    .array(yup
      .string()
      .oneOf(Object.values(CalendarEventTypeEnum))
      .required())
    .required(),
  employee: yup.string().nullable(),
  workingHours: yup
    .object({
      from: yup
        .number()
        .integer()
        .required(),
      to: yup
        .number()
        .integer()
        .required(),
    })
    .nullable(),
  services: yup
    .array(
      yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
        name: yup.string().required(),
        tabId: yup.string().required(),
        extra: yup.object().required(),
      }),
    )
    .required(),
});

export const AppointmentFiltersLocalstorageSchema = yup.object({
  createdAt: yup.number().min(0).integer().required(),
  values: AppointmentFiltersSchema.required(),
});
