import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Stack, ToggleButtonGroup, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';
import clsx from 'clsx';

import { ToggleButton } from '@linetweet/linetweet-ui';

import { AppointmentFilters } from '../types';
import { CalendarEventTypeEnum, Service } from '../../../types';
import { ICategory, ServiceTabSelect, ServiceTabsSelectOption } from '../../commons';
import { isArrayEqual } from '../../../utils';

import styles from './AppointmentsFilters.module.scss';

type AppointmentsFiltersProps = {
  isOpen: boolean;
  values: AppointmentFilters;
  defaultValues: AppointmentFilters;
  eventTypeCounters: { [key: string]: number };
  onClose: () => void;
  allServices: Service[];
  onSave: (updatedFilters: AppointmentFilters) => void;
};

const focusOptions: CalendarEventTypeEnum[] = [
  CalendarEventTypeEnum.APPOINTMENT,
  CalendarEventTypeEnum.BREAK,
  CalendarEventTypeEnum.BLOCKER,
];

export function AppointmentsFilters({
  isOpen,
  values,
  defaultValues,
  eventTypeCounters,
  onSave,
  onClose,
  allServices,
}: AppointmentsFiltersProps) {
  const intl = useIntl();
  const [currentValues, setCurrentValues] = useState(values);
  const [activeTabId, setActiveTabId] = useState(values.services.length > 0 ? values.services[0].tabId : '');

  useEffect(() => {
    if (allServices.length > 0 && !activeTabId) {
      setActiveTabId(allServices[0].category);
    }
  }, [allServices, activeTabId]);

  const serviceInputProps = useMemo(() => {
    const tabs = allServices.reduce((acc: ICategory[], service) => {
      if (acc.find((category) => service.category === category.id)) {
        return acc;
      }
      acc.push({
        id: service.category,
        name: intl.formatMessage({ id: `service.${service.category}` }),
      });
      return acc;
    }, []);

    const options = allServices.map(
      (service): ServiceTabsSelectOption => ({
        label: intl.formatMessage({ id: service.name }),
        value: service.id,
        name: service.name,
        tabId: service.category,
        extra: { ...service },
      }),
    );

    return {
      tabs,
      options,
    };
  }, [allServices]);

  const isSaveDisabled = useMemo(() => isArrayEqual(values, currentValues), [values, currentValues]);

  const onFocusChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: CalendarEventTypeEnum[]) => {
      setCurrentValues({
        ...currentValues,
        focus: value,
        ...(!value.includes(CalendarEventTypeEnum.APPOINTMENT) && {
          services: [],
        }),
      });
    },
    [currentValues],
  );

  const handleServices = useCallback(
    (value: ServiceTabsSelectOption[]) => {
      setCurrentValues({
        ...currentValues,
        ...(!currentValues.focus.includes(CalendarEventTypeEnum.APPOINTMENT) && {
          focus: [...currentValues.focus, CalendarEventTypeEnum.APPOINTMENT],
        }),
        services: value,
      });
    },
    [currentValues],
  );

  const onSelectAll = useCallback(() => {
    const selectedServiceIds = currentValues.services.map((serviceOption) => serviceOption.value);

    setCurrentValues({
      ...currentValues,
      services: [
        ...currentValues.services,
        ...serviceInputProps.options.filter(
          (serviceOption) => serviceOption.tabId === activeTabId && !selectedServiceIds.includes(serviceOption.value),
        ),
      ],
    });
  }, [activeTabId, currentValues, serviceInputProps]);

  const onDeselectAll = useCallback(() => {
    setCurrentValues({
      ...currentValues,
      services: currentValues.services.filter((serviceOption) => serviceOption.tabId !== activeTabId),
    });
  }, [activeTabId, currentValues]);

  const handleSave = useCallback(() => {
    onSave(currentValues);
    onClose();
  }, [currentValues]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <Stack direction="column" className={styles.container}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography className={styles.focusTitle}>{intl.formatMessage({ id: 'filter.title' })}</Typography>
            <IconButton className={styles.closeButton} edge="end" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <ToggleButtonGroup spacing={1} value={currentValues.focus} onChange={onFocusChange}>
            {focusOptions.map((option) => (
              <ToggleButton key={option} value={option} variant="rounded" color="secondary" className={styles.focusToggleButton}>
                {option.toString()}{' '}
                {!!eventTypeCounters && typeof eventTypeCounters[option] !== 'undefined' && (
                  <span className={clsx(styles.focusCounter, { [styles.selectedCounter]: currentValues.focus.includes(option) })}>
                    {eventTypeCounters[option]}
                  </span>
                )}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {(!currentValues.focus.length || currentValues.focus.includes(CalendarEventTypeEnum.APPOINTMENT)) && activeTabId && (
            <>
              <Typography className={styles.servicesTitle}>{intl.formatMessage({ id: 'appointment.service' })}</Typography>
              <ServiceTabSelect
                {...serviceInputProps}
                value={currentValues.services}
                onServiceChange={handleServices}
                onTabChange={setActiveTabId}
                activeTabId={activeTabId}
                ignoreResetOnTabChange
                ignoreServiceDuration
                withLightColors
                allowToggle
              />

              <Stack direction="row" justifyContent="flex-end" spacing={1} marginTop={3}>
                <Button className={styles.selectButton} variant="contained" onClick={onSelectAll}>
                  {intl.formatMessage({ id: 'appointment.selectAllServices' })}
                </Button>

                <Button className={styles.selectButton} variant="contained" onClick={onDeselectAll}>
                  {intl.formatMessage({ id: 'appointment.unselectAllServices' })}
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave} disabled={isSaveDisabled}>
          {intl.formatMessage({ id: 'calendar.submit' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
