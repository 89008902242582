import { Nullable } from '@linetweet/linetweet-ui';
import { createSlice } from '@reduxjs/toolkit';

export enum ConnectionType {
  Disconnected,
  InProgress,
  Connected,
}

interface InitialState {
  connectionStatus?: ConnectionType;
  slotsCalculationDays: Array<String | null>;
}

const initialState: InitialState = { slotsCalculationDays: [] };

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    connect: (state) => {
      state.connectionStatus = ConnectionType.InProgress;
    },
    disconnect: (state) => {
      state.connectionStatus = ConnectionType.Disconnected;
    },
    connected: (state) => {
      state.connectionStatus = ConnectionType.Connected;
    },
    statusesUpdated: (state, action) => {
      if (action.payload.slotsRecalculating) {
        state.slotsCalculationDays.push(action.payload.slotsRecalculationDay);
      } else {
        const indexOfDate = state.slotsCalculationDays.indexOf(action.payload.slotsRecalculationDay);
        if (indexOfDate > -1) state.slotsCalculationDays.splice(indexOfDate, 1);
      }
    },
  },
});

export const { connect, connected, disconnect, statusesUpdated } = slice.actions;
export default slice.reducer;
