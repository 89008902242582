import React, { useCallback, useContext, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box } from '@mui/material';
import { LoadingWrapper } from '@linetweet/linetweet-ui';

import { LocaleSelectContext } from 'layouts/LocaleProvider/LocaleProvider';
import {
  Header,
  AppointmentsCalendar,
  CreateCalendarEvent,
  EditCalendarEvent,
  AppointmentsCalendarContainerProps,
  CalendarEventCreateParams,
  CalendarEventEditParams,
} from 'features';

import { TimeHelper } from '../../utils';
import { Service } from '../../types';

import styles from './index.module.scss';
import { CalendarOverview } from '../../features/CalendarOverview';
import { useAppSelector } from '../../store/hooks';

export function Calendar() {
  const [editAppointmentData, setEditAppointmentData] = useState<CalendarEventEditParams | undefined>(undefined);
  const [appointmentCreateData, setAppointmentCreateData] = useState<CalendarEventCreateParams | null>(null);
  const [showCalendarOverview, setShowCalendarOverview] = useState(false);
  const [date, setDate] = useState(TimeHelper.toDayjs(Date.now()).startOf('day'));
  const localeContext = useContext(LocaleSelectContext);

  const storeState = useAppSelector((state) => state.store);

  const onDateChange = useCallback((value: string) => {
    setDate(TimeHelper.toDayjs(value));
  }, []);

  const onFormOpen: AppointmentsCalendarContainerProps['onAppointmentClick'] = useCallback(({ calendarEvent, isPastEvent }) => {
    setEditAppointmentData({
      appointmentId: calendarEvent.id,
      type: calendarEvent.type,
      employeeId: calendarEvent.suggestedEmployee,
      isDisabled: isPastEvent,
    });
  }, []);

  const onFormClose = useCallback(() => {
    setEditAppointmentData(undefined);
  }, []);

  const onCopyToNewAppointment = useCallback((params: CalendarEventCreateParams) => {
    setAppointmentCreateData(params);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={localeContext?.locale || 'en'}>
      <Box className={styles.calendarBoxWrapper}>
        <Header />
        <div className={styles.content}>
          <LoadingWrapper loading={!storeState.data || !storeState.data.id}>
            <AppointmentsCalendar
              date={date}
              onDateChange={onDateChange}
              onAppointmentClick={onFormOpen}
              onCreateAppointmentClick={(params?: { services: Service[]; time: number; date: string }) => {
                setAppointmentCreateData(params || { date: TimeHelper.toStandardFormat(date) });
              }}
              onShowCalendarOverviewClick={() => setShowCalendarOverview(true)}
            />
          </LoadingWrapper>
          {showCalendarOverview && <CalendarOverview date={date} onClose={() => setShowCalendarOverview(false)} />}
        </div>

        {editAppointmentData && (
          <EditCalendarEvent
            {...editAppointmentData}
            onClose={onFormClose}
            shouldReload={false}
            onCopyToNew={onCopyToNewAppointment}
          />
        )}
        {appointmentCreateData && <CreateCalendarEvent onClose={() => setAppointmentCreateData(null)} params={appointmentCreateData} />}
      </Box>
    </LocalizationProvider>
  );
}
