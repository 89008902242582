import { Middleware } from '@reduxjs/toolkit';
import { socketIo } from '../../utils/socketIo';

import { getUser, getUserSettings } from '../user/thunks';
import { getVersion } from '../health/thunks';

import { redirectToLoginPage } from '../../utils/user';
import { getStore } from '../store/thunks';
import { connected, disconnect, statusesUpdated } from '../global/slice';
import { updateShifts } from '../employees/slice';
import { addCalendarEvent, updateCalendarEvent, deleteCalendarEvent, syncCalendarEvents } from '../appointments/slice';
import { updateEventStats } from '../eventStats/slice';
import { deleteSlot, syncSlots } from '../calendarSlots/slice';

export const SocketIoMiddleware: Middleware<{}, any> = (api) => (next) => (action) => {
  if (action.type === 'global/connect') {
    if (!api.getState().global.connectionStatus) {
      socketIo.on('connect', async () => {
        console.log('connected');

        await api.dispatch(getUser() as any);
        await api.dispatch(getStore() as any);
        await api.dispatch(getUserSettings() as any);
        await api.dispatch(getVersion() as any);
        api.dispatch(connected() as any);
      });

      socketIo.on('disconnect', (status, details) => {
        // the reason of the disconnection, for example "transport error"
        console.log(status);

        // the low-level reason of the disconnection, for example "xhr post error"
        // @ts-ignore
        console.log(details?.message);

        // some additional description, for example the status code of the HTTP response
        // @ts-ignore
        console.log(details?.description);

        // some additional context, for example the XMLHttpRequest object
        // @ts-ignore
        console.log(details?.context);

        if (status === 'io server disconnect') {
          redirectToLoginPage();
        } else {
          api.dispatch(disconnect() as any);
        }
      });

      socketIo.on('connect_error', (data) => {
        console.log(`Disconnected w/ connect_error: ${data}`);
        if (data.message === 'unauthorized') {
          redirectToLoginPage();
        }

        if (data.message === 'xhr poll error' || data.message === 'websocket error') {
          api.dispatch(disconnect() as any);
        }
      });

      socketIo.on('error', (error) => {
        console.log(`error : ${error}`);
        api.dispatch(disconnect() as any);
      });

      socketIo.on('calendar-events-synced', (data) => {
        const { timeZone } = api.getState().store.data;
        api.dispatch(syncCalendarEvents({ data, timeZone }));
      });

      socketIo.on('calendar-event-added', (data) => {
        const { timeZone } = api.getState().store.data;
        api.dispatch(addCalendarEvent({ data, timeZone }));
      });

      socketIo.on('calendar-event-updated', (data) => {
        const { timeZone } = api.getState().store.data;
        api.dispatch(updateCalendarEvent({ data, timeZone }));
      });

      socketIo.on('calendar-event-deleted', (data) => {
        api.dispatch(deleteCalendarEvent(data));
      });

      socketIo.on('event-stats-updated', (data) => {
        api.dispatch(updateEventStats(data) as any);
      });

      socketIo.on('shifts-updated', (data) => {
        api.dispatch(updateShifts({ shifts: data.shifts, date: data.date }));
      });

      socketIo.on('slot-deleted', (data) => {
        api.dispatch(deleteSlot(data));
      });

      socketIo.on('slots-synced', (data) => {
        const { timeZone } = api.getState().store.data;
        api.dispatch(syncSlots({ data, timeZone }));
      });

      socketIo.on('statuses-updated', (data) => {
        api.dispatch(statusesUpdated(data));
      });

      socketIo.connect();
    }
  }

  next(action);
};
